import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';
import { CalioDialogComponent } from '@calio-lib/calio-dialog/calio-dialog.component';
import { BryntumCalendarEventModel, BryntumCalendarResourceModel } from '@client-models/bryntum-calendar-model';
import { WebhookEndpoints } from '@client-models/webhook-stats.model';
import { ApiErrorDialog } from '@db-models/api-error.model';
import { AppointmentServiceCategoryDbModel } from '@db-models/appointment-service-category-db.model';
import { AppointmentServiceDbModel } from '@db-models/appointment-service.model';
import { AppointmentStatusDbModel, AppointmentStatusModel } from '@db-models/appointment-status-db';
import { AvailabilityDbModel } from '@db-models/availability-db.model';
import { BookingLinkGenerator } from '@db-models/booking-link-generator.model';
import { CalendarAppointmentModel } from '@db-models/calendar-appointment.model';
import { CategoryDbModel } from '@db-models/category-db.model';
import { ConferenceWorkerModel } from '@db-models/conference-db.model';
import { ConfirmationModal } from '@db-models/confirmation-dialog.model';
import { CustomFieldModel, CustomFieldOptionModel } from '@db-models/custom-field.model';
import { CustomBookingDbModel, CustomerCustomFieldDbModel, CustomerDbModel, CustomerNotesDbModel, EventParticipantDbModel } from '@db-models/customer-db.model';
import { CustomerTypeDbModel } from '@db-models/customer-type-db.model';
import { DealsDbModel } from '@db-models/deals.model';
import { BookingEventDao } from '@db-models/event-booking.dao';
import { EventDbModel, EventSlotDbModel } from '@db-models/event-db.model';
import { MeetingsDbModel } from '@db-models/meetings-db.model';
import { MfaDbModel } from '@db-models/mfa.models';
import { NotificationDbModel } from '@db-models/notification-db.model';
import { PartnerDbModel } from '@db-models/partner-db.model';
import { PartnerSettingModel } from '@db-models/partner-payment-account.model';
import { PartnershipDbModel } from '@db-models/partnership-db.model';
import { PartnerReleaseNotesModel, ReleaseNotesModel } from '@db-models/release-notes.model';
import { SmartWidgetModel, SmartWidgetStepModel } from '@db-models/smart-widget.model';
import { StoreDbModel } from '@db-models/store-db.model';
import { StripeCreditCardModel } from '@db-models/stripe-credit-card.model';
import { TaxDbModel } from '@db-models/tax.model';
import { TemplateDbModel } from '@db-models/template.model';
import { WidgetAppointmentGroupModel } from '@db-models/widget-appointment-group.model';
import { WidgetConfiguration } from '@db-models/widget-configurations.model';
import { WorkerDbModel } from '@db-models/worker-db.model';
import { WorkflowsModel } from '@db-models/workflow-db.model';
import { ImportDynamicComponentService } from '@util-services/import-dynamic-component.service';
import { LoggerService } from '@util-services/logger.service';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    private matDialog: MatDialog,
    private importDynamicComponentService: ImportDynamicComponentService
  ) { }

  openTemplateEventDialog(data: {
    template?: TemplateDbModel,
    activeSubscriptionId?: number,
    templateType?: string,
    appointmentSubjectTemplate?: TemplateDbModel,
    eventSubjectTemplate?: TemplateDbModel,
    appointmentMailTemplate?: TemplateDbModel,
    eventMailTemplate?: TemplateDbModel,
    showTemplateTypeSwitcher?: boolean,
    showTestEmailButton?: boolean
    disabled?: boolean
  }): MatDialogRef<any> {
    let title: string;
    if (data.template && data.template.type === 'email') {
      title = 'templates_component.set_template';
    } else if (data.template && data.template.type === 'sms') {
      title = 'templates_component.set_template';
    } else if (data.template && data.template.type === 'general') {
      title = 'templates_component.set_template';
    } else if (data.templateType === 'complete-email') {
      title = 'templates_component.set_template';
    }
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importTemplateFormDialogComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '550px',
    });
    return dialogRef;
  }

  openCalioSettingsDialog(partnerSettings: PartnerSettingModel[]): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importCalioSettingFormComp(),
        compData: {
          partnerSettings
        },
        dialogTitle: 'calio_settings_component.dashboard_settings',
      },
      panelClass: 'calenso-generic-dialog-box',
      maxWidth: '550px'
    });
    return dialogRef;
  }

  openCustomerNoteFormDialog(customerNote: CustomerNotesDbModel): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importCustomerNotesFormComp(),
        compData: {
          customerNote
        },
        dialogTitle: 'crm_component.customer_name_title',
      },
      panelClass: 'calenso-generic-dialog-box',
      maxWidth: '450px',
    });
    return dialogRef;
  }

  openCreateSettingDialog(compData: {
    [key: string]: string | number
  }): MatDialogRef<CalioDialogComponent, any> {
    return this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importCreateSettingComponent(),
        compData,
        dialogTitle: 'crm_component.mps_create_title',
      },
      panelClass: 'calenso-generic-dialog-box',
      maxWidth: '450px',
    });
  }

  openQuestionFieldFormDialog(
    data: {
      customFieldDbModel: CustomFieldModel,
      formType: string
    }, title: string
  ): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importQuestionFieldFormComp(),
        compData: data,
        dialogTitle: title,
      },
      width: '460px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openDealFormDialog(
    data: {
      deal: DealsDbModel,
      formType: string
    }, title: string
  ): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importDealFormComp(),
        compData: data,
        dialogTitle: title,
      },
      width: '500px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openCompanySettingTranslationFormDialog(partnerDbModel: PartnerDbModel): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importCompanySettingTranslationFormComp(),
        compData: {
          partnerDbModel
        },
        dialogTitle: 'settings_component.company_description_title',
      },
      width: '500px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openStripeCardDialog(stripeCardModel: StripeCreditCardModel, dialogTitle: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importStripeCardDialogComp(),
        compData: {
          stripeCardModel
        },
        dialogTitle,
      },
      width: '500px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openResellerInviteDialog(partnershipDB: PartnershipDbModel): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importResellerInviteComp(),
        compData: {
          partnershipDB
        },
        dialogTitle: 'reseller_component.reseller_invite_title',
      },
      width: '500px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openServiceCategoryDialog(data: {
    showCancelButton: boolean, formType: string, appointmentServiceCategoryDbModel: AppointmentServiceCategoryDbModel
  }, title: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importServiceCategoryComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '500px',
    });
    return dialogRef;
  }

  openAppointmentServiceDialog(data: {
    // taxes: TaxDbModel[],
    // appointmentServiceCategories: AppointmentServiceCategoryDbModel[],
    appointmentServiceDbModel: AppointmentServiceDbModel,
    formType: string,
    leadFollowUp: boolean,
  }, title: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importAppointmentServiceComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '600px',
    });
    return dialogRef;
  }

  openAppointmentQuestionServiceDialog(data: {
    appointmentServiceDbModel: AppointmentServiceDbModel
  }): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importAppointmentServiceQuestionComp(),
        compData: data,
        dialogTitle: 'appointment_services_questions_component.title',
        dialogSubTitle: 'event_question_fields_component.you_manage_appointment_service',
        dialogSubtitleTranslationJson: { appointment_service: data.appointmentServiceDbModel.name }
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '1000px',
    });
    return dialogRef;
  }

  openEmployeeFormDialog(data: {
    // storeWorker?: { key: string, value: WorkerDbModel[], temp: any },
    formType?: string,
    worker?: WorkerDbModel,
    workerDbModels?: WorkerDbModel[]
  }): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importEmployeeFormComp(),
        compData: data,
        // dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '500px',
    });
    return dialogRef;
  }

  openStoresFormDialog(
    data: {
      formType: any,
      storeDbModel: any,
    },
    title: string
  ): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importStoreFormComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '500px',
    });
    return dialogRef;
  }

  openWidgetAppointmentGroupFormDialog(data: {
    formType: string,
    widgetAppointmentGroupDbModel: WidgetAppointmentGroupModel,
  }, title: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importWidgetAppointmentGroupFormComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '500px',
    });
    return dialogRef;
  }

  openCalendarSetupDialog(
    data: {
      code?: string,
      calendarType?: string,
      workerId?: number,
      showWorkerOptionsInForm?: boolean,
      redirectTo?: boolean,
      workerCalenderList?: boolean[]
    },
    title: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importCalendarSetupDialogComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });
    return dialogRef;
  }

  openRecurringAvailabilitiesFormDialog(data: {
    worker: WorkerDbModel,
    screenType: string,
    availability?: AvailabilityDbModel,
    formType: string,
    showWorkerOptionsInForm: boolean,
  }, title: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importAvailabilitiesFormSwitcherComp(),
        compData: data,
        dialogTitle: title,
      },
      width: '550px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openAbsensesFormDialog(
    data: {
      worker: WorkerDbModel,
      partnerDbModel: PartnerDbModel,
      showWorkerOptionsInForm: boolean,
    },
    title: string
  ): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importAbsensesFormComp(),
        compData: data,
        dialogTitle: title,
      },
      width: '550px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openCustomerTypesFormDialog(data: {
    formType: string,
    customerTypeDbModel: CustomerTypeDbModel,
  }, title: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importCustomerTypesFormComp(),
        compData: data,
        dialogTitle: title,
      },
      width: '500px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openCalendarTypesFormDialog(data: {
    formType: string,
    categoryDbModel: CategoryDbModel,
  }, title: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importCreateCalendarTypeComp(),
        compData: data,
        dialogTitle: title,
      },
      width: '500px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openPartnerSettingDialog(data: {
    setting_id?: number,
    template_type?: string,
    appointment_setting_id?: number,
    appointment_title?: string,
    event_setting_id?: number,
    placeholder?: string,
    event_title?: string,
    showAppointmentEventSwitch?: boolean,
    lead_time_setting_id?: number,
    lead_time_title?: string,
    disabled?: boolean
  }, title: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importPartnerSettingModalComp(),
        compData: data,
        dialogTitle: title,
      },
      width: '500px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openJsonDiffViewerDialog(
    data: {
      json: {
        [key: string]: string
      },
      showJsonParser: boolean,
      showLeftOnly?: boolean
    },
    title: string
  ) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importJsonDiffViewerComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '700px',
    });
    return dialogRef;
  }

  openJsonViewerDialog(data: {
    json: string,
    showJsonParser: boolean
  }, title: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importJsonViewerComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '700px',
    });
    return dialogRef;
  }

  openTaxesFormDialog(data: {
    formType: string,
    taxDbModel: TaxDbModel
  }, title: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importTaxesFormComp(),
        compData: data,
        dialogTitle: title,
      },
      width: '500px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openConnectStripeStepperDialog(data: {
    isStripeEnabled: boolean,
    isDialogLayout: boolean
  }, title: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importConnectStripeStepperComp(),
        compData: data,
        dialogTitle: title,
      },
      width: '610px',
      disableClose: true,
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openCalensoWidgetDialog(data: {
    partnerBookingName: string,
    secret: string,
    internal: boolean,
    token: string,
    loggedInWorker?: WorkerDbModel,
    customer?: CustomerDbModel,
  }, title: string, customHeaderClass: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importCalensoWidgetModalComp(),
        compData: data,
        dialogTitle: title,
        customHeaderClass,
      },
      panelClass: 'calenso-widget-dialog-box',
      width: '1000px',
    });
    return dialogRef;
  }

  openRebookAppointmentDialog(data: {
    uuid: string,
    appointment: CalendarAppointmentModel,
    partner: PartnerDbModel,
    userMode: string,
  }) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importRebookAppointmentComp(),
        compData: data,
        dialogTitle: 'appointment_detail_component.rebook_appointment',
      },
      width: '1000px',
      panelClass: 'calenso-generic-dialog-box'
    });
    return dialogRef;
  }

  openDeleteEventConfirmationDialog(data: {
    confirmationMsg?: string,
    confirmBtn?: string,
    cancelBtn?: string,
    showToggleButton?: boolean,
    toggleButtonTitle?: string,
    showCancelReasonTextArea?: boolean,
    cancelReason?: string,
    isCancelReasonRequired?: boolean,
    cancelReasonHelperText?: string,
    originalEventName?: string,
  }, title: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importDeleteEventConfirmationModalComp(),
        compData: data,
        dialogTitle: title,
      },
      maxWidth: '500px',
    });
    return dialogRef;
  }

  openConfirmationDialog(data: ConfirmationModal, title?: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importConfirmationModalComp(),
        compData: data,
        dialogTitle: title,
      }
    });
    return dialogRef;
  }

  openAppointmentFormDialog(data: {
    appointment: CalendarAppointmentModel,
    workers: WorkerDbModel[]
  }, title: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        compData: data,
        dialogTitle: title,
      },
      width: '500px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openEventSlotParticipantDialog(data: {
    participants: {
      regular: EventParticipantDbModel[],
      waitingList: EventParticipantDbModel[]
    },
    slot: EventSlotDbModel,
    event: EventDbModel
  }, title: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importEventSlotParticipantComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      // height: '1000px',
      width: '800px',
    });
    return dialogRef;
  }

  openEventSlotEditDialog(data: {
    slot: EventSlotDbModel,
    event: EventDbModel
  }, title: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importEventSlotEditComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '600px'
    });
    return dialogRef;
  }

  openSmartActionTriggerDialog(data: {
    smartActionTrigger: PartnerSettingModel,
    disabled?: boolean
  }, title: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importSmartActionTriggerComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '500px'
    });
    return dialogRef;
  }

  openImageUploadDialog(
    data: {
      imageUploadType?: string,
      workerDbModel?: WorkerDbModel
    },
    title: string,
    modelWidth = '500px'
  ) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importImageUploadDialogComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: modelWidth
    });
    return dialogRef;
  }

  openDeleteAccountConfirmationDialogComp(data: {}, title: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importDeleteAccountConfirmationDialogComp(),
        compData: data,
        dialogTitle: title,
      },
    });
    return dialogRef;
  }

  openCustomerBookingDeleteDialog(data: {
    bookings: CustomBookingDbModel[],
    landingpageDeactivateCancellationReasonFeature: PartnerSettingModel,
    landingpageCancellationReasonIsOptionalFeature: PartnerSettingModel
  }, title: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importCustomerBookingDeleteComp(),
        compData: data,
        dialogTitle: title,
      },
      // width: '340px'
    });
    return dialogRef;
  }

  openEventCopyCodeDialog(data: {
    config: {
      imagePath: string,
      imageWidth: number,
      localeTitleKey: string,
      localeDescKey: string,
      links: [{
        displayScriptCode: string,
        displayLinkCode: string,
        codeToCopy: string,
      }]
    }
  }, title: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importEventCopyCodeComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '500px'
      // width: '340px'
    });
    return dialogRef;
  }

  openEventAddSlotDialog(data: {}, title: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importEventAddSlotBottomSheetComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '560px',
    });
    return dialogRef;
  }

  openBryntumCalendarEventEditorDialog(data: {
    eventRecord: BryntumCalendarEventModel,
    resourceRecord: BryntumCalendarResourceModel,
    workers: WorkerDbModel[],
    partnerSettings: PartnerSettingModel[]
  }, title: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importBryntumEventEditorComp(),
        compData: data,
        dialogTitle: title,
      },
      autoFocus: true,
      panelClass: 'calenso-generic-dialog-box',
      width: '500px'
    });
    return dialogRef;
  }

  openCalioPermissionModal() {
    LoggerService.log('inside openCalioPermissionModal');
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importCalioPermissionModalComp(),
        compData: {},
        dialogTitle: 'Permission Management',
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '500px'
    });
    return dialogRef;
  }

  viewPermissionModal(
    data: {
      uuid: string
    },
    title: string
  ) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.viewPermissionModalComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '400px'
    });
    return dialogRef;
  }

  createRoleModal(
    data?: {
      uuid?: string
    },
    title?: string
  ) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.createRolesComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '600px',
      disableClose: true
    });
    return dialogRef;
  }

  createApiModal(
    data: {
      is_super_admin: boolean,
      partner_name: string,
      partner_uuid: string
    },
    title: string
  ) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.createApiComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '600px'
    });
    return dialogRef;
  }

  openMeetingSetupDialog(
    data: {
      code?: string,
      meetingType?: string,
      workerId?: number,
      eventId?: number,
      showWorkerOptionsInForm?: boolean,
      showEventOptionsInForm?: boolean,
      redirectTo?: number,
      source?: string
    },
    title: string
  ): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importMeetingSetupDialogComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });
    return dialogRef;
  }

  openReleaseNotesDialog(
    data: {
      releaseNotes: ReleaseNotesModel,
    },
    title: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importReleaseNotesDialogComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });
    return dialogRef;
  }

  openPartnerReleaseNotesDialog(
    data: {
      releaseNotes: PartnerReleaseNotesModel[]
    },
    title: string, subtitle: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importPartnerReleaseNotesDialogComp(),
        compData: data,
        dialogTitle: title,
        dialogSubTitle: subtitle,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });
    return dialogRef;
  }

  openSmartWidgetFormDialog(
    data: {
      smartWidgetData: SmartWidgetModel,
      formType: string
    },
    title: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importSmartWidgetFormDialogComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });
    return dialogRef;
  }

  openQrCodeDialog(
    data: {
      uuid?: string,
      base64String?: string
    },
    title: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importQrCodeDialogComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });

    return dialogRef;
  }

  openSmartWidgetEmbedCodeDialog(
    data: {
      widgetUUID: string,
      partnerUUID: string,
    },
    title: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importSmartWidgetEmbedCodeDialogComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });
    return dialogRef;
  }

  openSmartWidgetStepFormDialog(data: {
    formType: string,
    smartWidgetStepModel: SmartWidgetStepModel,
  }, title: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importSmartWidgetStepFormComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '500px',
    });
    return dialogRef;
  }

  openTemplateRestrictionDialog(data: {
    disabled?: boolean
  }, title: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importTemplateRestrictionDialogComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });
    return dialogRef;
  }

  openBookingQuestionOrderingDialog(
    data: {
      rawTitle: string,
      selectedTitle: string,
      pageType: string,
      selectedFormFields: CustomFieldModel[],
      deSelectedFormFields: CustomFieldModel[],
      stepDbModel?: SmartWidgetStepModel,
    },
    title: string,
  ): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importBookingQuestionOrderingComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });
    return dialogRef;
  }

  openHistoryDialog(
    data: {
      history: CustomerCustomFieldDbModel[]
      customFields: CustomFieldOptionModel[]
      customFieldType: string
    },
    title: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.formFieldHistory(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '600px',
    });
    return dialogRef;
  }

  openAuditLogsExportDialog(
    title: string
  ): MatDialogRef<CalioDialogComponent, any> {
    return this.matDialog.open(
      CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.auditLogsExport(),
        compData: {},
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });
  }

  openWebhookDialog(
    title: string,
    data?: WebhookEndpoints[]
  ): MatDialogRef<CalioDialogComponent, any> {
    return this.matDialog.open(
      CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.webhook(),
        compData: { data },
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });
  }

  openAppointmentStatusDialog(
    data: {
      formType: string,
      appointmentStatusModel: AppointmentStatusModel,
    },
    title: string,
  ): MatDialogRef<CalioDialogComponent, any> {
    return this.matDialog.open(
      CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.appointmentStatus(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });
  }

  openUpdateAppointmentStatusDialog(
    data: {
      appointment: CalendarAppointmentModel,
    },
    title: string,
  ): MatDialogRef<CalioDialogComponent, any> {
    return this.matDialog.open(
      CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.updateAppointmentStatus(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });
  }

  openAppointmentStatusHistoryDialog(
    data: {
      statuses: AppointmentStatusDbModel[],
      statusList: AppointmentStatusDbModel[]
    },
    title: string,
  ): MatDialogRef<CalioDialogComponent, any> {
    return this.matDialog.open(
      CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.showAppointmentStatusHistory(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '80vw',
    });
  }

  openYoutubeVideoPreviewDialog(
    option: CustomFieldOptionModel
  ): MatDialogRef<CalioDialogComponent, any> {
    return this.matDialog.open(
      CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.youtubeVideoPreview(),
        compData: {
          youtubeUrl: option.youtube_url
        },
        dialogTitle: null,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });
  }

  openSortMeetingProvidersDialog(
    data: {
      meetingProvider: MeetingsDbModel[],
      templates: TemplateDbModel[]
    },
    title: string,
    subtitle: string
  ): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.sortMeetingProvider(),
        compData: data,
        dialogTitle: title,
        dialogSubTitle: subtitle
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });
    return dialogRef;
  }

  openCancelSubscriptionDialog(
    data: {
      cancellationDate: string
    },
    title: string,
    subtitle?: string
  ): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.cancelSubscription(),
        compData: data,
        dialogTitle: title,
        dialogSubTitle: subtitle
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });
    return dialogRef;
  }

  openManageBusinessHoursDialog(
    data: {},
    title: string,
    subtitle: string,
  ): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.manageBusinessHoursDialog(),
        compData: data,
        dialogTitle: title,
        dialogSubTitle: subtitle
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });
    return dialogRef;
  }

  openManageSubscriptionDialog(
    data: {
      customer_uuid: string,
      current_subscription: number,
      payment_type: string,
      subscription_interval: string,
      worker_list: WorkerDbModel[]
    },
    title: string
  ): MatDialogRef<CalioDialogComponent, any> {
    return this.matDialog.open(
      CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.manageSubscription(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });
  }

  openEmailSmsPreviewDialog(
    data: {
      uuid: string,
    },
    title: string,
  ) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.emailSmsPreview(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '700px',
    });
    return dialogRef;
  }

  openHtmlPreviewDialog(
    data: {
      htmlContent: SafeHtml
    },
    title: string,
  ) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.openHtmlPreview(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '700px',
    });
    return dialogRef;
  }

  openEmailHistoryDialog(
    data: { uuid: string },
    title: string,
  ) {
    return this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.emailHistory(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '700px',
    });
  }

  openResetWorkerMfaDialog(
    data: {
      resetMfa: boolean,
      verifyMfa: boolean
    },
    title: string,
    subtitle?: string
  ): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.resetWorkerMfa(),
        compData: data,
        dialogTitle: title,
        dialogSubTitle: subtitle
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });
    return dialogRef;
  }

  openActivateMfaDialog(
    data: {
      mfaData: MfaDbModel
    },
    title: string,
    subtitle?: string
  ): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.activateMfa(),
        compData: data,
        dialogTitle: title,
        dialogSubTitle: subtitle
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '620px',
    });
    return dialogRef;
  }

  openMfaBackupCodesDialog(
    data: {
      mfaData: MfaDbModel
    },
    title: string,
    subtitle?: string
  ): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.showMfaBackupCodes(),
        compData: data,
        dialogTitle: title,
        dialogSubTitle: subtitle
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '620px',
      disableClose: true
    });
    return dialogRef;
  }

  openGlobalNotificationDialog(
    data: {
      partner: PartnerDbModel;
      notification?: NotificationDbModel,
    },
    title: string,
    subtitle?: string
  ): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.showGlobalNotification(),
        compData: data,
        dialogTitle: title,
        dialogSubTitle: subtitle
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '620px',
      disableClose: true
    });
    return dialogRef;
  }

  openCustomerModalForm(
    data: {
      requiredCustomerFormFieldsPartnerSetting: PartnerSettingModel
    },
    title: string,
  ): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.openCustomerModalForm(),
        compData: data,
        dialogTitle: title
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '700px',
      disableClose: true
    });
    return dialogRef;
  }

  openImagePreviewDialog(
    data: {
      imageUrl: string
    },
  ): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.ImageModalComponent(),
        compData: data
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '700px'
    });
    return dialogRef;
  }

  openShowMappedServicesDialog(
    data: {
      customFieldDbModel: CustomFieldModel
    },
    title: string,
    subTitle: string
  ): MatDialogRef<CalioDialogComponent, any> {
    return this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.mappedServicesDialog(),
        compData: data,
        dialogTitle: title,
        dialogSubTitle: subTitle
      },
      width: '450px',
      panelClass: 'calenso-generic-dialog-box',
    });
  }

  openRebookGroupAppointmentDialog(data: {
    uuid: string,
    event: BookingEventDao,
    partner: PartnerDbModel,
    userMode: string,
  }) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.rebookGroupAppointmentDialog(),
        compData: data,
        dialogTitle: 'appointment_detail_component.rebook_appointment',
      },
      width: '820px',
      panelClass: 'calenso-generic-dialog-box',
      disableClose: true
    });
    return dialogRef;
  }

  generateQrCodeDialog(
    data: {
      type: string,
      bookingLinksParams?: BookingLinkGenerator
      base64StringDownloadString?: string,
      base64StringPreviewString?: string,
      linkToCopy?: string
    },
    title: string,
  ): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.generateQrCodeComponent(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '600px',
      disableClose: true
    });
    return dialogRef;
  }

  deleteStoreConfirmationDialog(data: {
    stores: StoreDbModel[],
    currentStoreId: number,
    workersCount: number
  }) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.deleteStoreConfirmationDialog(),
        compData: data,
        dialogTitle: 'confirmation_modal_component.confirm_deletion',
      },
      width: '450px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  thirdPartyIntegratinoApiErrorDialog(data: ApiErrorDialog) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.thirdPartyIntegratinoApiErrorDialog(),
        compData: data,
        dialogTitle: 'external_calendar_settings_component.calendar_connection_error_dialog',
      },
      width: '600px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  conferenceWorkersList(data: {
    workers: ConferenceWorkerModel[]
  }) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.conferenceWorkersListDialog(),
        compData: data,
        dialogTitle: 'conference_dashboard_component.attendees',
      },
      width: '450px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  supportUserLoginDialog(data: {
    supportPartnerUuid: string;
  }) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.supportUserLoginDialog(),
        compData: data,
        dialogTitle: 'Login using the Support user',
      },
      width: '450px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  createIntegrationConfigDialog(data: {
    configuration: WidgetConfiguration
  }, title: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.createIntegrationConfigDialog(),
        compData: data,
        dialogTitle: title,
      },
      width: '450px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openCustomerLinksTableDialog(
    data: {
      appointments: CalendarAppointmentModel[],
      partnerHash: string
    }
  ): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.customerLinksTableDialog(),
        compData: data,
        dialogTitle: 'conference_dashboard_component.bookings',
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '600px',
    });
    return dialogRef;
  }

  openMergeCustomerDialog(
    data: {
      customers: CustomerDbModel[]
    }
  ): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.mergeCustomerDialog(),
        compData: data,
        dialogTitle: 'crm_component.merge_customer',
        dialogSubTitle: 'crm_component.merge_customers_hint'
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '650px',
    });
    return dialogRef;
  }

  openPredefineWorkflowListDialog(): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.predefineWorkflowListDialog(),
        compData: {},
        dialogTitle: 'workflow.active_workflow_title',
      },
      disableClose: true,
      panelClass: 'calenso-generic-dialog-box',
      width: '600px',
    });
    return dialogRef;
  }

  openWorkflowListDetailsDialog(data: { workflow: WorkflowsModel, partner: PartnerDbModel }): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.workflowListDetailsDialog(),
        compData: data,
        dialogTitle: 'workflow.title',
      },
      disableClose: true,
      panelClass: 'calenso-generic-dialog-box',
      width: '600px',
    });
    return dialogRef;
  }
}
