import { HttpContext, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { LANDING_PAGE_AUTH_REQUIRED } from '@constants/http-context-token';
import { PartnerSettingModel, SettingModel } from '@db-models/partner-payment-account.model';
import { AlertToastrService } from '@util-services/alert-toastr.service';
import { HttpClientService } from '@util-services/http-client.service';
import { LoggerService } from '@util-services/logger.service';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PartnerSettingService implements Resolve<PartnerSettingModel[]> {

  private httpClientService = inject(HttpClientService);
  private alertToastrService = inject(AlertToastrService);

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PartnerSettingModel[]> {
    return this.getPartnerSettings().pipe(
      catchError((error: HttpErrorResponse) => {
        LoggerService.error(error);
        return of([]);
      })
    );
  }

  definePartnerSetting(settingId: number, value: string): Observable<PartnerSettingModel> {
    const body: { setting_id: { value: number, operator: string } } = { setting_id: { value: settingId, operator: '=' } };

    return this.filterPartnerSettings(body).pipe(
      switchMap(partnerSettings => {
        if (partnerSettings?.length) {
          const partnerSetting = new PartnerSettingModel();
          partnerSetting.value = value;
          partnerSetting.setting_id = settingId;
          return this.editPartnerSettings(partnerSettings[0].id, partnerSetting);
        } else {
          const partnerSetting = new PartnerSettingModel();
          partnerSetting.value = value;
          partnerSetting.setting_id = settingId;
          return this.createPartnerSettings(partnerSetting);
        }
      })
    );
  }

  filterPartnerSettings(body: { setting_id: { value: number, operator: string } }): Observable<PartnerSettingModel[]> {
    return this.httpClientService.post('partners_settings/filter', body, {});
  }

  getPartnerSettingsByIds(ids: number[], useLandingPageAuth = false, authTokenUuid = ''): Observable<PartnerSettingModel[]> {
    let context: HttpContext;
    if (useLandingPageAuth) {
      context = new HttpContext().set(LANDING_PAGE_AUTH_REQUIRED, authTokenUuid);
    }

    return this.httpClientService.post('partners_settings/getSettingsByIds', { ids }, { context }).pipe(
      switchMap((partnerSettings: PartnerSettingModel[]) => {
        return of(partnerSettings ? partnerSettings : []);
      })
    );
  }

  editPartnerSettings(id: number, partnerSetting: PartnerSettingModel): Observable<PartnerSettingModel> {
    LoggerService.log('editPartnerSettings', id, partnerSetting);
    return this.httpClientService.put('partners_settings/' + id, partnerSetting, {});
  }

  createPartnerSettings(partnerSetting: PartnerSettingModel): Observable<PartnerSettingModel> {
    const body: { setting_id: number, value: string } = {
      setting_id: partnerSetting.setting_id,
      value: partnerSetting.value
    };
    return this.httpClientService.post('partners_settings', body, {});
  }

  savePartnerSettings(
    partnerSetting: PartnerSettingModel, useLandingPageAuth = false, authTokenUuid = ''
  ): Observable<PartnerSettingModel> {
    let context: HttpContext;
    const body: { [key: string]: number | string } = {
      setting_id: partnerSetting.setting_id,
      value: partnerSetting.value,
    };

    // append partner_id to body if present
    if (partnerSetting?.partner_id) {
      body.partner_id = partnerSetting.partner_id;
      useLandingPageAuth && (context = new HttpContext().set(LANDING_PAGE_AUTH_REQUIRED, authTokenUuid));
    }

    return this.httpClientService.post('partners_settings', body, { context });
  }

  deletePartnerSettings(id: number): Observable<{ success: boolean }> {
    return this.httpClientService.delete('partners_settings/' + id, {});
  }

  getPartnerSettings(): Observable<PartnerSettingModel[]> {
    return this.httpClientService.get('partners_settings', {}).pipe(
      switchMap((settings: PartnerSettingModel[]) => of(settings?.length ? settings : []))
    );
  }

  deletePartnerSetting(id: number): Observable<{ success: boolean }> {
    return this.httpClientService.delete('partners_settings/' + id, {});
  }

  getAllPartnerSettingsList(): Observable<SettingModel[]> {
    return this.httpClientService.get('settings/', {});
  }

  getAllPartnerSettingsByPartnerId(authTokenUuid = ''): Observable<PartnerSettingModel[]> {
    const context = new HttpContext().set(LANDING_PAGE_AUTH_REQUIRED, authTokenUuid);
    return this.httpClientService.get('partners_settings/', { context }).pipe(
      switchMap((result: PartnerSettingModel[]) => {
        return of(result?.length ? result : []);
      })
    );
  }

  updatePartnerSettings(partnerPaymentAccountModel: PartnerSettingModel, showToaster = true): Observable<PartnerSettingModel | HttpErrorResponse> {
    return this.savePartnerSettings(partnerPaymentAccountModel).pipe(
      switchMap(data => {
        showToaster && this.alertToastrService.showSuccess('settings_component.settings_saved_successfully');
        return of(data);
      }),
      catchError((error: HttpErrorResponse) => {
        this.alertToastrService.showError('settings_component.settings_failed');
        LoggerService.error(error);
        return of(error);
      })
    );
  }
}
