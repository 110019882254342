export const environment = {
  production: false,
  hostName: 'https://dev.my.calenso.com',
  clientDomain: 'https://calio.calenso.com',
  bookingWebcomponentUrl: 'https://dev.webcomponent.calenso.com/booking.html',
  smartWidgetUrl: 'https://dev.smartwidget.calenso.com',
  bookingPageUrl: 'https://dev.book.calenso.com',
  authServiceHost: 'https://dev.auth.calenso.com',
  authServiceRestApiV1: '/api/',
  exportNodeServiceHost: 'https://dev.export-queue.calenso.com',
  exportNodeServiceRestApiV1: '/api/',
  notificationNodeServiceHost: 'https://dev.notification-queue.calenso.com',
  notificationNodeServiceRestApiV1: '/api/',
  restAPI: '/api/v1/',
  v2RestAPI: '/api/v2/',
  encryptedKey: 'eyJ0eXiOiJDb3QiLCJhbGciOiJIUzI1NiJ9eyJ',
  configJsonPath: 'fe_assets/config.dev.json',
  filesImagePath: 'https://dev.my.calenso.com/files/',
  imagePath: 'https://dev.my.calenso.com/files/workers/avatar/',
  bookingPageBackgroundImagePath: 'https://dev.my.calenso.com/files/partners/booking_page_background_logo/',
  partnersImagePath: 'https://dev.my.calenso.com/files/partners/logo/',
  landingPageImagePath: 'https://dev.my.calenso.com/files/partners/landingpage_logo/',
  faviconImagePath: 'https://dev.my.calenso.com/files/partners/favicon_logo/',
  emailImagePath: 'https://dev.my.calenso.com/files/partners/email_logo/',
  eventImagePath: 'https://dev.my.calenso.com/webroot/files/events/image/',
  storeImagePath: 'https://dev.my.calenso.com/webroot/files/stores/logo/',
  svgDealsImagePath: 'https://dev.my.calenso.com/files/deals/svg_logo/',
  cssTemplatesImagePath: 'https://dev.my.calenso.com/images/templates/',
  qrCodeImagePath: 'https://dev.my.calenso.com/files/qrcodes/',
  deployUrl: 'https://calio.calenso.com',
  bookingSiteUrl: 'https://dev.book.calenso.com',
  bookingSiteEventUrl: 'https://dev.book.calenso.com/{partner-name}/events',
  registerUrl: 'https://calenso.bcpt.ch/registrierung',
  webcomponentWidgetUrl: 'https://dev.webcomponent.calenso.com',
  pricingTableWebComponentUrl: 'https://dev.pricing.calenso.com',
  iframeWidgetUrl: 'https://dev.widget.calenso.com',
  registerationUrl: 'https://cloud.calenso.com/testversion/',
  version: '7.1.5',
  googleTagManagerCode: 'GTM-MJC5XGQ',
  redirect_uri: 'https://calio.calenso.com/app/settings/calendars',
  calendly_redirect_uri: 'https://calio.calenso.com/app/settings/calendly',
  setupPageRedirectURI: 'https://calio.calenso.com/app/setup/appointment/account?step=4',
  reseller_contract_url: 'https://www.calenso.com/reseller-contract',
  smartWidget: {
    integrationURL: 'https://dev.smartwidget.calenso.com/assets/smart-widget/main.js'
  },
  azureSSO: {
    redirectUri: 'https://calio.calenso.com/auth/sso'
  },
  stripePublicKey: "pk_test_qC6Q2zbwLgHgHEzXkHdKATbR",

  // secrets variables
  stripeConnectUrl: "",
  stripeCoupon: {
    flatrate: "",
    whitelabel: ""
  },
  office365Conf: {
    url: "",
    clientId: "",
    redirectURI: "",
    setupPageRedirectURI: "",
    response_type: "",
    state: "",
    scope: "",
    plainRedirectUrl: "",
    plainSetupPageRedirectURI: "",
    teamsScope: "",
    teamsRedirectURI: "",
    plainTeamsRedirectURI: ""
  },
  googleConf: {
    url: "",
    response_type: "",
    accessType: "",
    clientId: "",
    redirectURI: "",
    state: "",
    scope: "",
    approvalPrompt: "",
    setupPageRedirectURI: ""
  },
  bexioConf: {
    url: "",
    grantType: "",
    clientId: "",
    redirectURI: "",
    bexio_login_redirect: "",
    scope: [],
    bexio_uuid: ""
  },
  zoomConfig: {
    url: "",
    response_type: "",
    client_id: "",
    redirect_uri: ""
  },
  gotoMeetingConfig: {
    url: "",
    response_type: "",
    client_id: "",
    redirect_uri: ""
  },
  webexConfig: {
    url: "",
    response_type: "",
    client_id: "",
    redirect_uri: "",
    scope: ""
  }
};
